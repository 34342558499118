





















































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';

import {
  IUserProfile,
  IBrand, IBrandCreate, IBrandUpdate,
  ICategory,
} from '@/interfaces';
import {
  dispatchGetBrands,
  dispatchCreateBrand,
  dispatchUpdateBrand,
  dispatchGetCategories,
  dispatchInstallShopify,
} from '@/store/affi/actions';
import {
    readAffiBrands,
    readCategories,
    readImpersonated,
} from '@/store/affi/getters';
import { readHasAdminAccess } from '@/store/main/getters';
import { ttt } from '@/nutils';

@Component
export default class EditBrand extends Vue {
  public ttt = ttt;
  public valid = false;
  public shopName: string = '';
  public shopDescription: string = '';
  public shopURL: string = '';
  public brandLogo: string = '';
  public shopifyKey: string = '';
  public isLive: boolean = false;
  public companyName: string = '';
  public vatNumber: string = '';
  public street: string = '';
  public zipcode: string = '';
  public city: string = '';
  public country: string = '';
  public couponCode: string = '';
  public categories: ICategory[] = [];


  public async mounted() {
    await dispatchGetCategories(this.$store);
    await dispatchGetBrands(this.$store);
    this.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public reset() {
    this.shopName = '';
    this.shopDescription = '';
    this.shopURL = '';
    this.brandLogo = '';
    this.isLive = false;
    this.companyName = '';
    this.vatNumber = '';
    this.street = '';
    this.zipcode = '';
    this.city = '';
    this.country = '';
    this.couponCode = '';
    this.categories = [];
    this.$validator.reset();
    if (this.brand) {
      this.shopName = this.brand.shop_name;
      this.shopDescription = this.brand.shop_description;
      this.shopURL = this.brand.shop_url;
      this.brandLogo = this.brand.brand_logo;
      this.isLive = this.brand.is_live;
      this.companyName = this.brand.company_name;
      this.vatNumber = this.brand.vat_number;
      this.street = this.brand.street;
      this.zipcode = this.brand.zipcode;
      this.city = this.brand.city;
      this.country = this.brand.country;
      this.couponCode = this.brand.coupon_code;
      if (this.brand.categories)
        this.categories = this.allCategories.filter((c) => this.brand.categories.split(',').includes(c.id.toString()));
    }
  }

  get allCategories() {
    return readCategories(this.$store);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedBrand: IBrandUpdate = {
        shop_name: this.shopName,
      };
      if (this.shopDescription) {
        updatedBrand.shop_description = this.shopDescription;
      }
      if (this.shopURL) {
        updatedBrand.is_shopify = true;
      }
      if (this.brandLogo) {
        updatedBrand.brand_logo = this.brandLogo;
      }
      if (this.hasAdminAccess) {
         updatedBrand.is_live = this.isLive;
      }
      if (this.companyName) {
        updatedBrand.company_name = this.companyName;
      }
      if (this.vatNumber) {
        updatedBrand.vat_number = this.vatNumber;
      }
      if (this.street) {
        updatedBrand.street = this.street;
      }
      if (this.zipcode) {
        updatedBrand.zipcode = this.zipcode;
      }
      if (this.city) {
        updatedBrand.city = this.city;
      }
      if (this.country) {
        updatedBrand.country = this.country;
      }
      if (this.categories) {
        updatedBrand.categories = this.categories.join(',');
      }
      if (this.couponCode) {
        updatedBrand.coupon_code = this.couponCode;
      }
      await dispatchUpdateBrand(this.$store,
        { id: this.brand!.id, brand: updatedBrand });
      this.$router.push('/main/dashboard');
    }
  }

  public async installShopifyApp() {
    const data = {
        shopUrl: this.shopURL.replace('https://',''),
        callback: ( resp: any ) => {
            // tslint:disable-next-line:no-console
            console.log(resp);
            window.location.href = resp.msg;
        }
    };
    await dispatchInstallShopify(this.$store, data);
  }

  get brand() {
    const imp = readImpersonated(this.$store);
    const bs = readAffiBrands(this.$store)
        .filter( (b) => ( imp === -1 || b && b.owner_id === imp ) );
    return bs[0];
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }
}
